import { setCustomAttributes, setLanguage } from '@pisano/feedback-core/app/reducers';
import { ReportedError, createError } from '@pisano/feedback-core/app/utils/error';
import { triggerPostMessage } from '@pisano/feedback-core/app/utils/postMessage';
import { requestJson, requestText } from '@pisano/feedback-core/app/utils/request';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import endsWith from 'lodash/endsWith';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ENV } from '../../env';
import {
  customerLoaded,
  customerLoadingError,
  hidePreviousFeedbacks,
  openChat,
  setWidgetInitialDisplayStatus,
  widgetCssLoaded,
  widgetLoaded,
  widgetLoadingError,
} from './actions';
import {
  CLOSE_WIDGET,
  LOAD_WIDGET,
  LOAD_WIDGET_CSS,
  OPEN_PREVIOUS_FEEDBACK,
  OPEN_WIDGET,
  OPEN_WIDGET_ONCE,
  SEND_POST_MESSAGE_FROM_WIDGET,
} from './constants';
import { getWidgetStore, handleWidgetStore } from './helpers/widgetStores';
import modalWidgetFrameCss from './styles/modal-widget/frame.scss';
import modalWidgetInnerCss from './styles/modal-widget/inner.scss';
import plainWidgetFrameCss from './styles/plain-widget/frame.scss';
import plainWidgetInnerCss from './styles/plain-widget/inner.scss';

function simplifyWidget(widget) {
  const simplifiedWidget = {
    // UI stuff
    isEnabledOnMobile: widget.enabled_on_mobile,
    isFullPageOnMobile: widget.full_page_on_mobile,
    isModal: widget.modal_mode,
    isVisible: widget.is_visible && (!widget.enabled_urls || !widget.enabled_urls.length),
    modalToggleText: widget.modal_toggle_text,
    popoverText: widget.popover_text,
    defaultLanguageCode: widget.default_language_code,

    // Technical stuff
    enabledUrls: widget.enabled_urls,
    enabledDomains: widget.enabled_domains,
    isDomainEnabled: checkDomainIfEnabled(widget.enabled_domains),
    nodeId: widget.node_id,
    nodeLogoUrl: widget.node_logo_url,
    secureMode: widget.secure_mode,
    securityToken: widget.security_token,
    displayInterval: widget.display_interval,
    shouldHideWidget: widget.hide_widget,
    webChannel: widget.web_channel,

    // Design
    size: widget.size,
    insideWidgetMatrixQuestionView: widget.matrix_question_view_type,
    width: widget.width,
    widthType: widget.width_type,
    margin: widget.margin,
    fontColor: widget.font_color,
    primaryColor: widget.primary_color,
    secondaryColor: widget.secondary_color,
    radius: widget.radius,
    displayBranding: widget.pisano_branding,

    // Behaviour
    displayOnce: widget.display_once,
    displayOnceDelayPeriod: widget.display_once_delay_period,
    displayOnExit: widget.display_on_exit,
    preventMultipleFeedback: widget.prevent_multiple_feedback,
    multipleFeedbackDelayPeriod: widget.multiple_feedback_delay_period,
  };

  return Object.keys(simplifiedWidget)
    .filter((k) => simplifiedWidget[k] != null)
    .reduce((obj, k) => ({ ...obj, [k]: simplifiedWidget[k] }), {});
}

function checkDomainIfEnabled(enabledDomains) {
  const currentHostName = window.location.hostname;
  return (
    enabledDomains.reduce(
      (acc, domain) => endsWith(currentHostName, `.${domain}`) || currentHostName === domain || acc,
      false,
    ) ||
    currentHostName === ENV.HOSTNAME ||
    !enabledDomains.length
  );
}

function* loadWidgetCss({ payload }) {
  const frameCssUrl = payload.isModal ? modalWidgetFrameCss : plainWidgetFrameCss;
  const innerCssUrl = payload.isModal ? modalWidgetInnerCss : plainWidgetInnerCss;
  const [frameCss, innerCss] = yield all([call(requestText, frameCssUrl), call(requestText, innerCssUrl)]);
  yield put(widgetCssLoaded({ frameCss, innerCss }));
}

function* loadWidget({ payload: { code, lang, customer, customAttributes } }) {
  try {
    const requestParts = [`${ENV.API_URL}/v1/web_widgets/code/${code}?t=${new Date().getTime()}`];
    if (customer) {
      if (customer.external_id) {
        requestParts.push(`external_id=${customer.external_id}`);
      }
      if (customer.user_id) {
        requestParts.push(`user_id=${customer.user_id}`);
      }
    }
    const requestUrl = requestParts.join('&');
    const widget = yield call(requestJson, requestUrl);
    const simplifiedWidget = simplifyWidget(widget);

    if (!simplifiedWidget.isDomainEnabled) {
      throw createError(ReportedError, 'Domain Not Allowed');
    } else if (!simplifiedWidget.shouldHideWidget) {
      yield put(setLanguage({ code: lang || simplifiedWidget.defaultLanguageCode }));

      try {
        const attrs = Object.entries(customAttributes).map(([key, value]) => ({
          question: key,
          answer: value,
        }));
        const customAttrsHash = btoa(unescape(encodeURIComponent(JSON.stringify(attrs))));
        yield put(setCustomAttributes(customAttrsHash));
      } catch (err) {
        // Do nothing
      }

      yield call(loadWidgetCss, {
        payload: simplifiedWidget,
      });

      // load customer from widget script if passed as custom parameters
      if (customer && Object.keys(customer).length > 0) {
        yield put(customerLoaded(customer));
      }
      yield put(widgetLoaded(simplifiedWidget));
      yield put(setWidgetInitialDisplayStatus());
    } else if (simplifiedWidget.shouldHideWidget) {
      yield put(widgetLoaded(simplifiedWidget));
      yield put(setWidgetInitialDisplayStatus());
      yield sendPostMessageFromWidget({
        payload: {
          type: 'PSN_WIDGET_HID',
        },
      });
    }
  } catch (err) {
    if (err.response) {
      yield put(widgetLoadingError(JSON.parse(err.response)));
    } else {
      throw err;
    }
  }
}

function* loadCustomer() {
  const {
    widget: { customer, nodeId },
  } = yield select();

  if (customer && customer.id) {
    return;
  }

  const requestUrl = `${ENV.API_URL}/v2/web_widgets/${nodeId}/customers/visit`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      node_id: nodeId,
      ...(customer || {}),
    }),
  };
  try {
    const customer = yield call(requestJson, requestUrl, options);
    yield put(customerLoaded(customer));
  } catch (err) {
    if (err.response) {
      let error = null;
      try {
        error = JSON.parse(err.response.responseText);
      } catch {
        error = err.response.responseText;
      }
      yield put(customerLoadingError(error));
    } else {
      throw err;
    }
  }
}

function* triggerWidgetOpenEvent() {
  const {
    widget: { nodeId },
  } = yield select();

  yield sendPostMessageFromWidget({
    payload: {
      type: 'PSN_WIDGET_OPENED',
    },
  });

  handleWidgetStore(nodeId, {
    isWidgetDisplayedBefore: true,
    widgetInitialDisplayTime: new Date(),
  });
}

function* openWidget() {
  const {
    widget: { isModal },
  } = yield select();

  yield loadCustomer();

  if (isModal) {
    disableBodyScroll(document.querySelector('#psn-widget-content-frame'));
  }

  yield triggerWidgetOpenEvent();
}

function* handleOpenWidgetOnce() {
  const {
    widget: { displayOnce, isModal, nodeId },
  } = yield select();
  const { isWidgetDisplayedBefore } = getWidgetStore(nodeId);

  if (displayOnce) {
    if (isWidgetDisplayedBefore) {
      yield sendPostMessageFromWidget({
        payload: {
          type: 'PSN_WIDGET_DISPLAYED_BEFORE',
        },
      });
    } else {
      yield loadCustomer();
      yield triggerWidgetOpenEvent();
    }
  } else {
    yield loadCustomer();
    yield triggerWidgetOpenEvent();
  }

  if (isModal) {
    disableBodyScroll(document.querySelector('#psn-widget-content-frame'));
  }
}

function* closeWidget() {
  const state = yield select();
  if (state.widget.isModal) {
    enableBodyScroll(document.querySelector('#psn-widget-content-frame'));
  }

  yield sendPostMessageFromWidget({
    payload: {
      type: 'PSN_WIDGET_CLOSED',
    },
  });
}

function* openPreviousFeedback({ payload: { feedbackId, token } }) {
  yield put(openChat({ feedbackId, token }));
  yield put(hidePreviousFeedbacks());
}

export function* sendPostMessageFromWidget({ payload: { type, data, delay = 0 } }) {
  const state = yield select();
  const { webChannel, enabledDomains } = state.widget;

  if (enabledDomains.length) {
    triggerPostMessage({
      type,
      delay,
      origin: window.location.origin,
      data: {
        channel_id: webChannel.id,
        channel_name: webChannel.name,
        ...data,
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LOAD_WIDGET, loadWidget),
    takeLatest(LOAD_WIDGET_CSS, loadWidgetCss),
    takeEvery(OPEN_WIDGET, openWidget),
    takeEvery(OPEN_WIDGET_ONCE, handleOpenWidgetOnce),
    takeEvery(CLOSE_WIDGET, closeWidget),
    takeEvery(OPEN_PREVIOUS_FEEDBACK, openPreviousFeedback),
    takeLatest(SEND_POST_MESSAGE_FROM_WIDGET, sendPostMessageFromWidget),
  ]);
}
