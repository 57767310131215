import pickBy from 'lodash/pickBy';
import { QUESTION } from '../../../components/FlowStateElement/elementTypes';
import * as QuestionType from '../../../constants/questionTypes';
import localStorage from '../../../utils/localStorage';
import { DEVICE_ID, UUID_REGEX } from '../constants';

export function getCustomerData(state) {
  const data = {};
  if (state.flow) {
    data.id = state.flow.creatorId || ((state.widget || {}).customer || {}).id;
    const { questions } = state.flow;
    const flowElements = state.flow.elements;

    Object.keys(questions)
      .filter(
        (questionId) => !!questions[questionId].response || flowElements[questionId].question.style === 'external_id',
      )
      .forEach((questionId) => {
        const question = questions[questionId];
        const questionType = flowElements[questionId].question.style;
        switch (questionType) {
          case QuestionType.EMAIL:
            data.email = question.response;
            break;
          case QuestionType.NAMING:
            data.name = question.response;
            break;
          case QuestionType.PHONE_NUMBER:
            data.phone_number = question.response;
            break;
          case QuestionType.GENDER:
            data.gender = state.flow.elements[questionId].question.options
              .filter((opt) => opt.id === question.response)
              .map((opt) => opt.key)[0];
            break;
          case QuestionType.AGE:
            // (new Date().getYear() + 1900) gives the current year.
            data.birth_year = new Date().getYear() + 1900 - question.response;
            break;
          case QuestionType.EXTERNAL_ID:
            if (question.response) {
              data.external_id = question.response;
            }
            break;
          case QuestionType.CUSTOMER_SCHEMA:
            if (question.response) {
              data.schema = data.schema ?? {};
              data.schema[flowElements[questionId].question.key] = question.response;
            }
            break;
          default:
            break;
        }
      });
  }

  const customerFromQueryParam = state.node && state.node.customer ? state.node.customer : {};
  return Object.assign(customerFromQueryParam, data);
}

export default function getFeedbackFromState(state, incomplete = false) {
  const visibleQuestions = pickBy(
    state.flow.elements,
    (el) => el.type === QUESTION && (el.isVisible || (!el.isVisible && el.question.key)), // key means we preload responses
  );

  const questions = Object.keys(state.flow.questions).map((questionId) => ({
    id: questionId,
    style: visibleQuestions[questionId]?.question.style,
    ...state.flow.questions[questionId],
  }));

  const questionTypeElements = pickBy(state.flow.elements, (el) => el.type === QUESTION);
  questions.forEach((q) => {
    q.hidePersonalData = questionTypeElements[q.id].question.hidePersonalData;
  });

  const questionsWithOptions = Object.keys(state.flow.elements)
    .map((element) => state.flow.elements[element].question)
    .filter((question) => question !== undefined)
    .map((question) => ({ [question.id]: question.options.length > 0 }))
    .reduce((acc, currValue) => ({ ...acc, ...currValue }));

  const toBackendResponseFormat = (
    questionId,
    response,
    { ranking = null, hidePersonalData = true, active_storage_blob_id = null } = {},
  ) => {
    const questionElement = state.flow.elements[questionId];
    const backendResponseObj = {
      question_id: questionElement.question.id,
    };

    const questionHasOptions = questionsWithOptions[questionElement.question.id];
    const isDynamicQuestion = [QuestionType.DYNAMIC_MULTIPLE_CHOICE, QuestionType.DYNAMIC_MULTIPLE_VALUE].includes(
      state.flow.elements[questionId].question.style,
    );

    if (UUID_REGEX.test(response) && questionHasOptions && !isDynamicQuestion) {
      backendResponseObj.option_id = response;
    } else if (response.statementId) {
      backendResponseObj.question_id = response.statementId;
      backendResponseObj.option_id = response.statementOptionId;
    } else if (isDynamicQuestion) {
      const option = questionElement.question.options.find((opt) => opt.id === response || opt.key === response);
      backendResponseObj.body = Object.values(option.body)[0];
    } else {
      backendResponseObj.body = response;
    }

    if (typeof ranking === 'number') {
      backendResponseObj.ranking = ranking;
    }
    if (active_storage_blob_id) {
      backendResponseObj.active_storage_blob_id = active_storage_blob_id;
    }
    backendResponseObj.hidePersonalData = hidePersonalData;

    return backendResponseObj;
  };

  const timeSpentInSeconds = (Date.now() - state.flow.firstResponseTime) / 1000;

  return {
    device_id: localStorage.getItem(DEVICE_ID),
    created_at: Date.now(),
    node_id: state.node.id,
    flow_id: state.flow.id,
    user_id: state.flow.userId,
    origin_url: window.parent.location !== window.location ? document.referrer : document.location.href,
    language_id: state.language.currentLanguage.id,
    created_offline: false,
    time_spent_in_seconds: timeSpentInSeconds,
    responses: questions
      .filter(
        (q) =>
          !!q.response &&
          !!visibleQuestions[q.id] &&
          (!incomplete || (q.style !== 'dynamic_multiple_choice' && q.style !== 'dynamic_multiple_value')),
      ) // has response and visible
      .reduce((responses, questionItem) => {
        let newResponses = null;
        if (Array.isArray(questionItem.response)) {
          newResponses = questionItem.response.map((optId, index) =>
            toBackendResponseFormat(questionItem.id, optId, {
              active_storage_blob_id: questionItem.active_storage_blob_id,
              ranking: index,
              hidePersonalData: questionItem.hidePersonalData,
            }),
          );
        } else {
          newResponses = toBackendResponseFormat(questionItem.id, questionItem.response, {
            active_storage_blob_id: questionItem.active_storage_blob_id,
            hidePersonalData: questionItem.hidePersonalData,
          });
        }
        return responses.concat(newResponses);
      }, []),
    customer: getCustomerData(state),
    attachment_ids: questions.filter((q) => !!q.file).map((q) => q.attachmentId),
  };
}
