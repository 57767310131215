import * as Sentry from '@sentry/react';
import { PRODUCTION, SENTRY_DSN, SENTRY_ENABLED, SENTRY_ENV, SENTRY_TRACING_ORIGINS } from '../config';

export const SentryConfigure = () => {
  if (SENTRY_ENABLED) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENV,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          shouldCreateSpanForRequest: (url) => !!url.match(SENTRY_TRACING_ORIGINS),
        }),
      ],
      tracesSampleRate: 0.1, // Report only 10% of the errors to prevent overhead on server
      beforeSend(event) {
        if (PRODUCTION) {
          return event;
        }
        return null;
      },
      allowUrls: [/.*psn.*|^(https?:\/\/).*(psn.cx|pisano.com?).*/],

      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }
};

/**
 * Notifies all services with the given crash report.
 */
export const SentryReport = ({ error, ...params }) => {
  if (SENTRY_ENABLED) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        ...params,
        error,
        actions: window.PisanoActions,
      });
      Sentry.captureException(error);
    });
  } else {
    console.error('Caught an exception!', error);
  }
};
